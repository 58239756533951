import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Autoplay } from "swiper";

import sliderimage from 'assets/image/rafeek-slider1.png'
import sliderimage2 from 'assets/image/rafeek-slider2.png'
import sliderimage3 from 'assets/image/rafeek-slider3.png'

import sliderimagemb from 'assets/image/rafeek-slider1-mb.png'
import sliderimagemb2 from 'assets/image/rafeek-slider2-mb.png'
import sliderimagemb3 from 'assets/image/rafeek-slider3-mb.png'


const Homeslider = () => {
  return (
    <section className="px-md-0 px-2">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Pagination,Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        className="mt-md-4 mt-2 home-slider"
      >
        <SwiperSlide className="d-flex align-items-end">
          <figure className="position-relative">
            <img className="d-md-block d-none img-fluid" src={sliderimage} alt="rafeek prenez soi de vos proches"/>
            <img className="d-md-none d-flex img-fluid" src={sliderimagemb} alt="rafeek prenez soi de vos proches"/>
          </figure>
        </SwiperSlide>
        <SwiperSlide className="d-flex align-items-end">
          <figure className="slider-image-wrapper position-relative">
            <img className="d-md-block d-none img-fluid" src={sliderimage2} alt=""/>
            <img className="d-md-none d-flex img-fluid" src={sliderimagemb2} alt=""/>
          </figure>
        </SwiperSlide>
        <SwiperSlide className="d-flex align-items-end">
          <figure className="slider-image-wrapper position-relative">
            <img className="d-md-block d-none img-fluid" src={sliderimage3} alt=""/>
            <img className="d-md-none d-flex img-fluid" src={sliderimagemb3} alt=""/>
          </figure>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Homeslider;
