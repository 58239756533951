import { useEffect } from "react";
import Aos from "aos";

const Fourthprocessus = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  });
  return (
    <div className="cercle-child-clicked-text px-4 py-2 position-relative">
      <p className="mb-0 justify-text processus-ip-desk" data-aos="fade-left">
        Coordination et gestion des remplacements. Prise en charge de
        l’intégralité des démarches administratives. Astreinte disponible 24H/24
        et 7J/7.
      </p>
      <span className="processus-number" data-aos="fade-up">4</span>
    </div>
  );
};

export default Fourthprocessus;
