import React from 'react'
import service1 from 'assets/image/service1-image.png'


const Firstservice = ({click}) => {
  return (
    <div className='col-lg-10 col-md-12 col-12 service-child position-relative' onClick={click}>
        <img className='w-100' src={service1} alt=""/>
        <button className='service-btn-1'>Accompagnements</button>
    </div>
  )
}

export default Firstservice