import { useEffect } from 'react';
import Aos from "aos";
import service3 from "assets/image/service3-clicked-image.png";

const Thirdclicked = () => {
  useEffect(()=>{
    Aos.init({
      duration:1000
    })
  })
  return (
    <div className="row service-child">
      <div className="col-lg-5 col-md-7 col-7 position-relative" data-aos="fade-left">
        <img className="w-100" src={service3} alt="" />
        <span className="last-service-text"><button className="service-btn-3">Assistance administrative</button></span>
      </div>
      <article className="col-lg-5 col-md-5 col-5 d-flex flex-column justify-content-center ps-0 pe-0" data-aos="fade-left">
        <p className="last-service-description justify-text">
          Le personnel qualifié de Rafeek est à votre disposition pour assister
          administrativement vos proches. En s’occupant de la gestion
          du courrier et de prendre des rendez-vous en cas de besoin.
        </p>
      </article>
    </div>
  );
};

export default Thirdclicked;
