import React from 'react'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "aos/dist/aos.css";


import Home from 'views/Home/Home'

const App = () => {
  return (
    <>
      <Home/>
    </>
  )
}

export default App