import React from "react";

const SubmitCv = ({ handleFileChange, fileRef, fileCv, handleClearFile }) => {
  return (
    <div className="position-relative">
      <label
        htmlFor="fileCv"
        className="submit-file-styling d-flex justify-content-center align-items-center mt-4 rounded"
      >
        {fileCv ? (
          `${fileCv.name} est ajouté`
        ) : (
          <div className="d-flex flex-column">
            <div className="text-center">
              <span>Téléchargez votre CV</span>{" "}
              <span>ou faites le glisser et déposer-le ici</span>
            </div>
            <div className="text-center">
              <span className="text-muted">
                Seulement des fichier .doc, .docx, .pdf, .rtf
              </span>
            </div>
          </div>
        )}
      </label>
      <span
        className="file-delete-styling text-danger"
        onClick={handleClearFile}
      >
        {fileCv ? "supprimer" : null}
      </span>
      <input
        className="d-none"
        id="fileCv"
        type={"file"}
        onChange={handleFileChange}
        ref={fileRef}
      />
    </div>
  );
};

export default SubmitCv;
