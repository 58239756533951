import ReactPlayer from "react-player";
import { useState } from "react";
import videoimg from "assets/image/video-img.png";
import playicon from "assets/icon/play-video.svg";
import rafeek from "assets/icon/work-rafeek.png";
const Homevideo = () => {
  const [play, setPlay] = useState(false);

  const handleplay = () => {
    setPlay((prev) => !prev);
  };
  const handlestop = () => {
    setPlay((prev) => !prev);
  };
  return (
    <section className="home-vide-bg">
      <div className="container d-flex flex-column justify-content-center align-items-center home-video-wrapper">
        <h2 className="text-center">بالك مرتاح على حبابك</h2>
        <h3 className="text-center">مع رفيق</h3>
        <div className="position-relative home-video-wrapper">
          <img className="img-fluid" src={videoimg} alt="rafeek video" />
          <img
            className="play-video-icon"
            src={playicon}
            alt=""
            onClick={handleplay}
          />
        </div>
        <div className="travaillez-chez-rafeek d-flex justify-content-end">
          <img className="img-fluid" src={rafeek} alt="" />
        </div>
      </div>
      <div
        className={
          play ? "youtube-video-wrapper" : "youtube-video-wrapper d-none"
        }
      >
        <div className="bg-video" onClick={handlestop}></div>
        <div className="youtube-video">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=47BKdVlT7aY"
            playing={play}
            controls={true}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </section>
  );
};

export default Homevideo;
