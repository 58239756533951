import React from 'react'

const Navigation = () => {
  return (
    <nav className='rafeek-navigation'>
        <div className='container d-lg-flex d-none justify-content-between align-items-start'>
            <div className='child-navigation-wrapper d-flex flex-column justify-content-center align-items-center position-relative'>
                <a href='#qui-sommes-nous' className='main-navigation-link text-decoration-none'>Qui sommes nous?</a>
            </div>

            <div className='child-navigation-wrapper1 d-flex flex-column justify-content-center align-items-center position-relative'>
                <a href='#nos-services' className='main-navigation-link text-decoration-none'>Nos services</a> 
            </div>

            <div className='child-navigation-wrapper2 d-flex flex-column justify-content-center align-items-center position-relative'>
                <a href='#processus' className='main-navigation-link text-decoration-none'>Notre processus</a>  
            </div>
            
        </div>
    </nav>
  )
}

export default Navigation