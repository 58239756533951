import { useEffect } from "react";
import Aos from "aos";

const Fifthprocessus = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  });
  return (
    <div className="cercle-child-clicked-text px-4 py-2 position-relative">
      <p className="mb-0 justify-text processus-ip-desk" data-aos="fade-left">
        Rafeek s’engage sur la qualité des interventions. Échanges réguliers et
        communication avec la famille et les proches. Suivi mensuel au domicile
        de nos clients avec un compte rendu. Disponibilité totale du responsable
        de famille. Sécurisation complète des auxiliaires vie avec des
        équipements adaptés.
      </p>
      <span className="processus-number" data-aos="fade-up">5</span>
    </div>
  );
};

export default Fifthprocessus;
