import React from "react";

const SubmitStatus = ({ error, message }) => {
  return (
    <div
      className={
        error
          ? "alert alert-success submit-status-css"
          : "alert alert-danger submit-status-css"
      }
      role="alert"
    >
      {message}
    </div>
  );
};

export default SubmitStatus;
