import React from "react";
import rafeekicon from "assets/icon/rafeek-icon.svg";
import rafeekabout from "assets/image/rafeekabout.png";
import abouticon from "assets/icon/abouticon.svg";
const Aboutrafeek = () => {
  return (
    <div className="position-relative" id="qui-sommes-nous">
      <div className="container">
        <img
          className="rafeek-about-icon d-md-block d-none"
          src={rafeekicon}
          alt=""
        />
        <div className="row align-items-center rafeek-about-wrapper">
          <article className="col-md-8 col-8 d-flex flex-column">
            <div className="about-p-width about-rafeek-heading d-flex align-items-md-center ">
              <div>
                <div className="d-flex justify-content-between">
                  <h1 className="mb-0">Rafeek</h1>
                  <img
                    className="img-fluid rafeek-heading-img"
                    src={abouticon}
                    alt=""
                  />
                </div>
                <p className="justify-text">
                  est une solution <strong>d’aide à domicile</strong> qui vous
                  permet d’accompagner vos proches au quotidien dans{" "}
                  <strong>leurs besoins, attentes et souhaits</strong> tout en
                  vous procurant <strong>une tranquillité d'esprit.</strong>
                </p>
              </div>
            </div>
            <div>
              <p className="rafeek-about-second-p justify-text about-p-width">
                Nous vous proposons un<strong> projet personnalisé</strong>{" "}
                comprenant <strong>plusieurs types d’accompagnement</strong>{" "}
                pour répondre à tous vos besoins. Vos proches seront entre les
                mains de personnes <strong>qualifiées et bienveillantes</strong>
                , sélectionnées avant tout pour leur{" "}
                <strong>savoir-être</strong>.
              </p>
            </div>
            <p className="rafeek-about-third-p justify-text about-p-width">
              Nos aînés ont pris soin de nous pendant notre jeunesse, à nous de
              prendre soin d’eux au moment où nous leur devenons indispensables
              au quotidien.
            </p>
          </article>
          <figure className="col-md-4 col-4 rafeeq-about-women">
            <img
              className="about-rafeek-image img-fluid"
              src={rafeekabout}
              alt=""
            />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Aboutrafeek;
