import {useState} from 'react'

import Firstprocessus from 'views/Home/components/processus/Firstprocessus'
import Secondprocessus from 'views/Home/components/processus/Secondprocessus'
import Thirdprocessus from 'views/Home/components/processus/Thirdprocessus'
import Fourthprocessus from 'views/Home/components/processus/Fourthprocessus'
import Fifthprocessus from 'views/Home/components/processus/Fifthprocessus'
import Sixthprocessus from 'views/Home/components/processus/Sixthprocessus'

import processusicon from 'assets/icon/rafeek-processus.svg'

const Notreprocessus = () => {
    
    const [clicked,setclicked] = useState({
        firstprocess:false,
        secondprocess:false,
        thirdprocess:false,
        fourthprocess:false,
        fifthprocess:false,
        sixthporcess:false
    })
    const firstclick = ()=>{
        setclicked(prev=>{
            return{
                firstprocess:!prev.firstprocess,
                secondprocess:false,
                thirdprocess:false,
                fourthprocess:false,
                fifthprocess:false,
                sixthporcess:false
            }
        })
    }
    const secondclick = ()=>{
        setclicked(prev=>{
            return{
                firstprocess:false,
                secondprocess:!prev.secondprocess,
                thirdprocess:false,
                fourthprocess:false,
                fifthprocess:false,
                sixthporcess:false
            }
        })
    }
    const thirdclick = ()=>{
        setclicked(prev=>{
            return{
                firstprocess:false,
                secondprocess:false,
                thirdprocess:!prev.thirdprocess,
                fourthprocess:false,
                fifthprocess:false,
                sixthporcess:false
            }
        })
    }
    const fourthclick = ()=>{
        setclicked(prev=>{
            return{
                firstprocess:false,
                secondprocess:false,
                thirdprocess:false,
                fourthprocess:!prev.fourthprocess,
                fifthprocess:false,
                sixthporcess:false
            }
        })
    }
    const fifthclick = ()=>{
        setclicked(prev=>{
            return{
                firstprocess:false,
                secondprocess:false,
                thirdprocess:false,
                fourthprocess:false,
                fifthprocess:!prev.fifthprocess,
                sixthporcess:false
            }
        })
    }
    const sixthclick = ()=>{
        setclicked(prev=>{
            return{
                firstprocess:false,
                secondprocess:false,
                thirdprocess:false,
                fourthprocess:false,
                fifthprocess:false,
                sixthporcess:!prev.sixthporcess
            }
        })
    }

    const geText= ()=>{
        if(clicked.firstprocess){
            return <Firstprocessus/>
        }
        if(clicked.secondprocess){
            return <Secondprocessus/>
        }
        if(clicked.thirdprocess){
            return <Thirdprocessus/>
        }
        if(clicked.fourthprocess){
            return <Fourthprocessus/>
        }
        if(clicked.fifthprocess){
            return <Fifthprocessus/>
        }
        if(clicked.sixthporcess){
            return <Sixthprocessus/>
        }
    }
    const text = geText()
  return (
    <section className='container mt-4 position-relative' id='processus'>
        <h2 className='notre-processus-h2'>Notre processus</h2>
        <div className='cercle-wrapper d-md-flex d-none justify-content-center'>
            <div className='cercle position-relative'>
                <div className='cercle-child-1 d-flex flex-column align-items-center' onClick={firstclick}>
                    <span>1</span>
                    <p>Écoute & conseils</p>
                </div>
                <div className='cercle-child-2 d-flex flex-column align-items-center' onClick={secondclick}>
                    <span>2</span>
                    <p>Devis gratuit</p>
                </div>
                <div className='cercle-child-3' onClick={thirdclick}>3</div>
                <div className='cercle-child-4' onClick={fourthclick}>4</div>
                <div className='cercle-child-5 d-flex flex-column align-items-center' onClick={fifthclick}>
                    <span>5</span>
                    <p>Suivi mensuel</p>
                </div>
                <div className='cercle-child-6 d-flex flex-column align-items-center' onClick={sixthclick}>
                    <span>6</span>
                    <p>Espace client</p>
                </div>
                <div className='cercle-child-text-3'>Sélection de l’intervenant</div>
                <div className='cercle-child-text-4'>Interventions à votre domicile</div>
                {text ? text : <Firstprocessus/>}
            </div>
        </div>
        <div className='processus-mobile d-md-none position-relative ms-4'>
            <div className={clicked.firstprocess ? 'd-flex processus-mobile-component-wrapper align-items-center processus-active mt-2 ' : 'd-flex processus-mobile-component-wrapper align-items-center mt-4 fade-in'}>
                <div className='processus-mobile-number d-flex align-items-end' onClick={firstclick}>
                    <h6 className='mb-0'>1</h6>
                    <h3 className='mb-4 process-child-first-h3'>Écoute & conseils</h3>
                </div>
                <div className='processus-mobile-desc ms-5 position-relative'>
                    <p className='mb-0 px-4 py-2 justify-text'>Une personne de l’agence se déplace à votre domicile aﬁn de réaliser une évaluation gratuite de vos besoins. Le responsable Rafeek analyse tous les besoins et vous conseille sur les solutions les mieux adaptées.</p>
                    <span className="processus-number-mb">1</span>
                </div>
            </div>
            <div className={clicked.secondprocess ? 'd-flex processus-mobile-component-wrapper align-items-center processus-active mt-3' : 'd-flex processus-mobile-component-wrapper align-items-center mt-4 ps-2 fade-in'}>
                <div className='processus-mobile-number d-flex align-items-end' onClick={secondclick}>
                    <h6 className='mb-0'>2</h6>
                    <h3 className='mb-4 process-child-h3'>Devis gratuit</h3>
                </div>
                <div className='processus-mobile-desc ms-5 position-relative'>
                    <p className='mb-0 px-4 py-2 justify-text processus-2-mobile'>
                        Après cette évaluation, un devis personnalisé et sans engagement vous est remis.
                    </p>
                    <span className="processus-number-mb">2</span>
                </div>
            </div>
            <div className={clicked.thirdprocess ? 'd-flex processus-mobile-component-wrapper align-items-center processus-active mt-3' : 'd-flex processus-mobile-component-wrapper align-items-center mt-4 ps-2 fade-in'}>
                <div className='processus-mobile-number d-flex align-items-end' onClick={thirdclick}>
                    <h6 className='mb-0'>3</h6>
                    <h3 className='mb-4 process-child-h3'>Écoute & conseils</h3>
                </div>
                <div className='processus-mobile-desc ms-5 position-relative'>
                    <p className='mb-0 px-4 py-2 justify-text processus-3-mobile'>
                        Après la signature du contrat, nous vous proposerons plusieurs intervenants compatibles pour vous accompagner.<br/> Une fois l'intervenant choisi, un planning et un contrat de prestation service vous sera adressé.
                    </p>
                    <span className="processus-number-mb">3</span>
                </div>
            </div>
            <div className={clicked.fourthprocess ? 'd-flex processus-mobile-component-wrapper align-items-center processus-active mt-3' : 'd-flex processus-mobile-component-wrapper align-items-center mt-4 ps-2 fade-in'}>
                <div className='processus-mobile-number d-flex align-items-end' onClick={fourthclick}>
                    <h6 className='mb-0'>4</h6>
                    <h3 className='mb-4 process-child-h3'>Interventions à votre domocile</h3>
                </div>
                <div className='processus-mobile-desc ms-5 position-relative'>
                    <p className='mb-0 px-4 py-2 justify-text processus-4-mobile'>
                        Coordination et gestion des remplacements.<br/>Prise en charge de l’intégralité des démarches administratives.  Astreinte disponible 24H/24 et 7J/7.
                    </p>
                    <span className="processus-number-mb">4</span>
                </div>
            </div>
            <div className={clicked.fifthprocess ? 'd-flex processus-mobile-component-wrapper align-items-center processus-active mt-3' : 'd-flex processus-mobile-component-wrapper align-items-center mt-4 ps-2 fade-in'}>
                <div className='processus-mobile-number d-flex align-items-end' onClick={fifthclick}>
                    <h6 className='mb-0'>5</h6>
                    <h3 className='mb-4 process-child-h3'>Suivi mensuel</h3>
                </div>
                <div className='processus-mobile-desc ms-5 position-relative'>
                    <p className='mb-0 px-4 py-2 justify-text'>
                        Rafeek s’engage sur la qualité des interventions.  Échanges réguliers et communication avec la famille et les proches.  Suivi mensuel au domicile de nos clients avec un compte rendu.  Disponibilité totale du responsable de famille.  Sécurisation complète des auxiliaires vie avec des équipements adaptés.
                    </p>
                    <span className="processus-number-mb">5</span>
                </div>
            </div>
            <div className={clicked.sixthporcess ? 'd-flex processus-mobile-component-wrapper align-items-center processus-active mt-3' : 'd-flex processus-mobile-component-wrapper align-items-center mt-4 ps-2 fade-in'}>
                <div className='processus-mobile-number d-flex align-items-end' onClick={sixthclick}>
                    <h6 className='mb-0'>6</h6>
                    <h3 className='mb-4 process-child-h3'>Espace client</h3>
                </div>
                <div className='processus-mobile-desc ms-5 position-relative'>
                    <p className='mb-0 px-4 py-2 justify-text'>
                        Dès la souscription de votre contrat, vous pouvez demander l’accès à votre espace client, ainsi, vous pourrez via le site internet ou l’application mobile, consulter votre planning mensuel, vos factures, vos contrats et votre messagerie.
                    </p>
                    <span className="processus-number-mb">6</span>
                </div>
            </div>
            <img className='processus-icon' src={processusicon} alt=''/>
        </div>
        <img className='d-lg-flex d-none processus-icon-desk' src={processusicon} alt=''/>
    </section>
  )
}

export default Notreprocessus