import {useEffect} from "react";
import Aos from 'aos'

const Firstprocessus = () => {
    useEffect(()=>{
        Aos.init({
            duration:1000
        })
    })
  return (
    <div className="cercle-child-clicked-text px-4 py-2 position-relative">
      <p className="mb-0 justify-text processus-ip-desk" data-aos="fade-left">
        Une personne de l’agence se déplace à votre domicile aﬁn de réaliser une
        évaluation gratuite de vos besoins.<br/> Le responsable Rafeek analyse tous
        les besoins et vous conseille sur les solutions les mieux adaptées.
      </p>
      <span className="processus-number" data-aos="fade-up">1</span>
    </div>
  );
};

export default Firstprocessus;
