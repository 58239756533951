import React from "react";
import service4 from 'assets/image/service4-image.png'

const Fourthservice = ({click}) => {
  return (
    <div className="col-lg-10 col-md-12 col-12 service-child position-relative first-service" onClick={click}>
      <img className="w-100" src={service4} alt="" />
      <button className="service-btn-1">Aide à l'autonomie</button>
    </div>
  );
};

export default Fourthservice;
