import React from "react";
import logo from "assets/image/logo.png";
import logomobile from "assets/image/logomobile.png";
import menu from "assets/image/menu.png";
import Sidemenu from "components/Sidemenu";
const Header = () => {
  return (
    <header className="py-lg-4 py-2">
      <div className="container d-flex justify-content-between align-items-center border-bottom">
        <div className="d-md-none d-flex justify-content-center align-items-center">
          <button
            className="border-0 bg-transparent me-2"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
          >
            <img className="menu-mobile img-fluid" src={menu} alt=""/>
          </button>
          <Sidemenu/>
          <figure>
            <img className="rafeek-mobile-logo img-fluid" src={logomobile} alt="Rafeek logo" />
          </figure>
        </div>
        <figure className="d-md-flex d-none">
          <img
            className="rafeek-header-logo img-fluid"
            src={logo}
            alt="Rafeek logo"
          />
        </figure>
        <div className="button-wrapper d-flex flex-lg-row flex-column">
          <button className="border-0 text-white bg-transparent px-md-4">
            <a className="text-decoration-none text-white" href="#contact" title="Prise de contact">
              Prise de contact
            </a>
          </button>
          <button className="border-0 text-white bg-transparent px-md-4">
            <a className="text-decoration-none text-white" href="#nous-rejoindre" title="Nous rejoindre">
              Nous rejoindre
            </a>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
