import React from "react";
import SubmitStatus from "./SubmitStatus";
import SubmitCv from "./SubmitCv";
const Formulaire = ({change,currentState,error,message,render,renderFileSubmit,handleFileChange,fileRef,fileCv,handleClearFile,fileStatusRender,fileStatusMessage}) => {
  return (
    <>
      <div className="d-flex flex-column justify-content-end mt-4">
        <label htmlFor="fullname-input text-start" title="Nom et prénom">
          Nom et prénom
        </label>
        <input
          id="fullname-input"
          className="contact-form-inputs py-2 mt-2 ps-2"
          type={"text"}
          name="fullName"
          value={currentState.fullName}
          onChange={change}
        />
      </div>
      <div className="row mt-4">
        <div className="col-md-6 col-8 d-flex flex-column justify-content-end">
          <label htmlFor="phone-input text-start" title="N° de téléphone">
            N° de téléphone
          </label>
          <div className="d-flex">
          <input type={'text'} value={'+212'} className="contact-form-inputs pre-maroc-number py-2 mt-2 ps-2 me-2" readOnly/>
          <input
            id="phone-input"
            className="contact-form-inputs py-2 mt-2 ps-2 w-100"
            type={"text"}
            name="telephone"
            value={currentState.telephone}
            onChange={change}
          />
          </div>
        </div>
        <div className="col-md-6 col-4 d-flex flex-column justify-content-end">
          <label htmlFor="ville-input text-start" title="ville">
            Ville
          </label>
          <input
            id="ville-input"
            className="contact-form-inputs py-2 mt-2 ps-2"
            type={"text"}
            name="ville"
            value={currentState.ville}
            onChange={change}
          />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-end mt-4">
        <label htmlFor="email-input text-start">Adresse mail</label>
        <input
            id="email-input"
            className="contact-form-inputs py-2 mt-2 ps-2"
            title="Nom et prénom"
            type={"text"}
            name="email"
            value={currentState.email}
            onChange={change}
        />
      </div>
      {
        renderFileSubmit?
        <SubmitCv handleFileChange={handleFileChange} fileRef={fileRef} fileCv={fileCv} handleClearFile={handleClearFile}/>:null
      }
      {
        fileStatusRender?<span className={fileStatusMessage.isError?"d-block text-center text-danger mt-4 file-error-styling":"d-block text-center text-success mt-4 file-error-styling"}>{fileStatusMessage.message}</span>:null
      }
      <div className="d-flex justify-content-lg-center justify-content-start form-button-wrapper">
        <button className="send-form-btn border-0 py-1">Envoyer</button>
      </div>
      {
        render?
        <SubmitStatus error={error} message={message}/>:
        null
      }
    </>
  );
};

export default Formulaire;
