import {useEffect} from "react";
import Aos from "aos";
import service1 from "assets/image/service1-clicked-image.png";

const Firstclicked = () => {
  useEffect(()=>{
    Aos.init({
      duration:1000
    })
  })
  return (
    <div className="row service-child">
      <div className="col-lg-5 col-md-7 col-7 position-relative" data-aos="fade-left">
        <img className="w-100" src={service1} alt="" />
        <span className="last-service-text"><button className='service-btn-1'>Accompagnements</button></span>
      </div>
      <article className="col-lg-5 col-md-5 col-5 d-flex flex-column justify-content-center align-items-center ps-0 pe-0" data-aos="fade-left">
        <p className="last-service-description justify-text adjust-text">
        Rafeek met à votre disposition des intervenants formés et
        spécialisés afin d’accompagner vos proches dans les actes
        ordinaires de la vie courante tels que des promenades à
        l’extérieur, l’aide à la prise de repas ou une visite chez le médecin.
        </p>
      </article>
    </div>
  );
};

export default Firstclicked;
