import { useEffect } from "react";
import Aos from "aos";

const Thirdprocessus = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  });
  return (
    <div className="cercle-child-clicked-text px-4 py-2 position-relative">
      <p className="mb-0 justify-text processus-ip-desk" data-aos="fade-left">
        Après la signature du contrat, nous vous proposerons plusieurs
        intervenants compatibles pour vous accompagner.<br/> Une fois l'intervenant
        choisi, un planning et un contrat de prestation service vous sera
        adressé.
      </p>
      <span className="processus-number" data-aos="fade-up">3</span>
    </div>
  );
};

export default Thirdprocessus;
