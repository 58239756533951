import Header from "./components/Header";
import Footer from "./components/Footer";

import React from 'react'

const Index = ({children}) => {
  return (
    <>
        <Header/>
            {children}
        <Footer/>
    </>
  )
}

export default Index