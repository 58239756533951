import { useEffect } from "react";
import Aos from "aos";
import service4 from "assets/image/service4-clicked-image.png";

const Fourthclicked = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  });
  return (
    <div className="row service-child">
      <div
        className="col-lg-5 col-md-7 col-7 position-relative"
        data-aos="fade-left"
      >
        <img className="w-100" src={service4} alt="" />
        <span className="last-service-text">
          <button className="service-btn-1">Aide à l'autonomie</button>
        </span>
      </div>
      <article
        className="col-lg-5 col-md-5 col-5 d-flex flex-column justify-content-center ps-0 pe-0"
        data-aos="fade-left"
      >
        <p className="last-service-description justify-text">
          Les intervenants Rafeek sont aux côtés de vos proches pour les
          assister dans les actes essentiels de la vie quotidienne tels que
          l’aide au lever, au coucher et à l’entretien de l’hygiène.
          <br /> Rafeek peut également vous proposer une garde de nuit, qu’il
          s’agisse d’un besoin ponctuel ou récurent.
        </p>
      </article>
    </div>
  );
};

export default Fourthclicked;
