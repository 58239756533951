import { useEffect } from 'react';
import Aos from "aos";
import service2 from 'assets/image/service2-clicked-image.png'

const Secondclicked = () => {
  useEffect(()=>{
    Aos.init({
      duration:1000
    })
  })
  return (
    <div className="row service-child">
      <div className="col-lg-5 col-md-7 col-7 position-relative" data-aos="fade-left">
        <img className="w-100" src={service2} alt="" />
        <span className="last-service-text"><button className="service-btn-2">Soutien des activités intellectuelles</button></span>
      </div>
      <article className="col-lg-5 col-md-5 col-5 d-flex flex-column justify-content-center ps-0 pe-0" data-aos="fade-left">
        <p className="last-service-description justify-text">
        Rafeek aidera vos proches à réaliser des activités qui contribueront à leur bien-être mental et social.<br/> Des activités adaptées aux capacités intellectuelles de chacun qui permettront et favoriseront l'éveil intellectuel, la créativité et l'estime de soi.
        </p>
      </article>
    </div>
  );
};

export default Secondclicked;
