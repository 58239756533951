import {useEffect} from "react";
import Aos from 'aos'

const Secondprocessus = () => {
    useEffect(()=>{
        Aos.init({
            duration:1000
        })
    })
  return (
    <div className="cercle-child-clicked-text px-4 py-2 position-relative">
      <p className="mb-0 justify-text processus-ip-desk" data-aos="fade-left">
        Après cette évaluation, un devis personnalisé et sans engagement vous
        est remis.
      </p>
      <span className="processus-number" data-aos="fade-up">2</span>
    </div>
  );
};

export default Secondprocessus;
