import {useState} from 'react'
import Firstservice from 'views/Home/components/service/Firstservice'
import Secondservice from 'views/Home/components/service/Secondservice'
import Thirdservice from 'views/Home/components/service/Thirdservice'
import Fourthservice from 'views/Home/components/service/Fourthservice'

import Firstclicked from 'views/Home/components/service/Firstclicked'
import Secondclicked from 'views/Home/components/service/Secondclicked'
import Thirdclicked from 'views/Home/components/service/Thirdclicked'
import Fourthclicked from 'views/Home/components/service/Fourthclicked'

const Nosservice = () => {
    const [clicked,setclicked] = useState({
        firstservice:false,
        secondservice:false,
        thirdservice:false,
        fourthservice:false
    })
    const firstclick = ()=>{
        setclicked(prev=>{
            return{
                firstservice:!prev.firstservice,
                secondservice:false,
                thirdservice:false,
                fourthservice:false
            }
        })
    }
    const secondclick = ()=>{
        setclicked(prev=>{
            return{
                firstservice:false,
                secondservice:!prev.secondservice,
                thirdservice:false,
                fourthservice:false
            }
        })
    }
    const thirdclick = ()=>{
        setclicked(prev=>{
            return{
                firstservice:false,
                secondservice:false,
                thirdservice:!prev.thirdservice,
                fourthservice:false
            }
        })
    }
    const fourthclick = ()=>{
        setclicked(prev=>{
            return{
                firstservice:false,
                secondservice:false,
                thirdservice:false,
                fourthservice:!prev.fourthservice
            }
        })
    }
  return (
    <section className='container' id='nos-services'>
        <div className='services-text'>
            <h2 className='text-uppercase'>Quand</h2>
            <h3 className='text-uppercase'>Faire appel à</h3>
            <h4>Nos services?</h4>
        </div>
        <div className='row'>
            {!clicked.fourthservice?<Fourthservice click={fourthclick}/>:<Fourthclicked/>}
            {!clicked.firstservice ? <Firstservice click={firstclick}/> : <Firstclicked/>}
            {!clicked.secondservice ? <Secondservice click={secondclick}/> : <Secondclicked/>}
            {!clicked.thirdservice ? <Thirdservice click={thirdclick}/> : <Thirdclicked/>}
            
        </div>
    </section>
  )
}

export default Nosservice