import React from 'react'

import Index from "layout/shared/Index";
import Navigation from 'components/Navigation';
import Homeslider from 'components/Homeslider';
import Aboutrafeek from 'components/Aboutrafeek';
import Nosservice from 'components/Nosservice';
import Notreprocessus from 'components/Notreprocessus';
import Rafeeksocial from 'components/Rafeeksocial';
import Contact from 'components/Contact';
import Rejoindre from 'components/Rejoindre';
import Homevideo from 'components/Homevideo';


const Home = () => {
  return (
    <>
        <Index>
            <main>
              <Navigation/>
              <Homeslider/>
              <Aboutrafeek/>
              <Nosservice/>
              <Notreprocessus/>
              <Rafeeksocial/>
              <div className='bg-color-change'>
                <Contact/>
                <Rejoindre/>
                <Homevideo/>
              </div>
            </main>
        </Index>
    </>
  )
}

export default Home