import React from 'react'
import logo from 'assets/image/logomobile.png'
import linkedin from 'assets/icon/linkedin-icon.png'
import instagram from 'assets/icon/instagram-icon.png'
const Footer = () => {
  return (
    <footer className='footer-wrapper py-3'>
      <div className='container d-flex justify-content-between'>
        <div>
          <img className='footer-logo' src={logo} alt="rafeek logo"/>
        </div>
        <nav className='footer-links d-flex justify-content-between align-items-center'>
          <a href='https://www.linkedin.com/company/rafeekmaroc/' target={'_blank'} rel="noreferrer" title='rafeek linkedin'>
            <img src={linkedin} alt="rafeek linkedin"/>
          </a>
          <a href='https://www.instagram.com/rafeek.maroc/?hl=fr' target={'_blank'} rel="noreferrer" title='rafeek instagram'>
            <img src={instagram} alt="rafeek instagram"/>
          </a>
        </nav>
      </div>
    </footer>
  )
}

export default Footer