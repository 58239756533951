import React from 'react'
import rafeekprocessus from 'assets/image/processus-image.png'
import linkedin from 'assets/icon/linkedin-icon.png'
import instagram from 'assets/icon/instagram-icon.png'

const Rafeeksocial = () => {
  return (
    <section className='position-relative'>
        <img className='img-fluid' src={rafeekprocessus} alt=""/>
        <div className='d-flex flex-column rafeek-social-tele'>
            <a className='text-decoration-none text-white rafeek-social-appelez-nous text-uppercase' href='tel:0522274867' title='APPELEZ NOUS AU'>APPELEZ NOUS AU</a>
            <a className='text-decoration-none text-white rafeek-social-phone' href='tel:0522274867' title='0522274867'>0522274867</a>
        </div>
        <div className='d-flex flex-column rafeek-social-media'>
            <a className='text-decoration-none text-white text-uppercase rafeek-social-suivez-nous' href='tel:0522274867' title='Suivez-nous'>Suivez-nous</a>
            <div className='d-flex justify-content-end align-items-center rafeek-social-media-accounts'>
                <a href='https://www.linkedin.com/company/rafeekmaroc/' className='me-2' target={'_blank'} rel="noreferrer" title='rafeek linkedin'>
                    <img src={linkedin} alt="rafeek linkedin"/>
                </a>
                <a href='https://www.instagram.com/rafeek.maroc/?hl=fr' target={'_blank'} rel="noreferrer" title='rafeek instagram'>
                    <img src={instagram} alt="rafeek instagram"/>
                </a>
            </div>
        </div>
    </section>
  )
}

export default Rafeeksocial