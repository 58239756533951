import React from "react";
import service2 from 'assets/image/service2-image.png'

const Secondservice = ({click}) => {
  return (
    <div className="col-lg-10 col-md-12 col-12 service-child position-relative" onClick={click}>
      <img className="w-100" src={service2} alt="" />
      <button className="service-btn-2">
        Soutien des activités intellectuelles
      </button>
    </div>
  );
};

export default Secondservice;
