import React from "react";

const Sidemenu = () => {
  return (
    <div
      className="offcanvas offcanvas-start w-75"
      data-bs-scroll="true"
      tabIndex="-1"
      id="offcanvasWithBothOptions"
      aria-labelledby="offcanvasWithBothOptionsLabel"
    >
      <div className="offcanvas-header side-menu-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
          Menu
        </h5>
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div>
            <div className="rafeek-sidemenu d-flex justify-content-between">
                <a href="#qui-sommes-nous" title="Qui sommes nous?">Qui sommes nous?</a>
            </div>
            <div className="rafeek-sidemenu d-flex justify-content-between">
                <a href="#nos-services" title="Nos services">Nos services</a>
            </div>
            
            <div className="rafeek-sidemenu d-flex justify-content-between">
                <a href="#processus" title="Notre processus">Notre processus</a>
            </div>
            
        </div>
      </div>
    </div>
  );
};

export default Sidemenu;
