import { useEffect } from "react";
import Aos from "aos";

const Sixthprocessus = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  });
  return (
    <div className="cercle-child-clicked-text px-4 py-2 position-relative">
      <p className="mb-0 justify-text processus-ip-desk" data-aos="fade-left">
        Dès la souscription de votre contrat, vous pouvez demander l’accès à
        votre espace client, ainsi, vous pourrez via le site internet ou
        l’application mobile, consulter votre planning mensuel, vos factures,
        vos contrats et votre messagerie.
      </p>
      <span className="processus-number" data-aos="fade-up">6</span>
    </div>
  );
};

export default Sixthprocessus;
