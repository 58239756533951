import { useState } from "react";
import axios from "axios";
import contacticon from "assets/icon/contact-icon.svg";
import Formulaire from "./Formulaire";
import rafeekimg from "assets/image/contact-rafeek-image.png";
const Contact = () => {
  const [client, setClient] = useState({
    isClient: false,
    notClient: false,
  });
  const [form, setform] = useState({
    fullName: "",
    telephone: "",
    ville: "",
    email: "",
  });
  const [error,setError] =useState({
    error:false,
    message:'Message non envoyé'
  })
  const [render,setRender] = useState(false)
  const handleClient = (e) => {
    if (e.target.name === "isClient") {
      if (client.notClient === true) {
        setClient((prev) => {
          return { isClient: !prev.isClient, notClient: !prev.notClient };
        });
      } else {
        setClient((prev) => {
          return { ...prev, isClient: !prev.isClient };
        });
      }
    } else {
      if (client.isClient === true) {
        setClient((prev) => {
          return { isClient: !prev.isClient, notClient: !prev.notClient };
        });
      } else {
        setClient((prev) => {
          return { ...prev, notClient: !prev.notClient };
        });
      }
    }
  };
  const handleFormChange = (e) => {
    setform((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const selected = () => {
    if (client.isClient === true) {
      return true;
    } else if (client.notClient === true) {
      return true;
    } else {
      return false;
    }
  };
  const checked = selected();
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (
      checked &&
      form.fullName &&
      typeof form.fullName === "string" &&
      form.telephone &&
      form.telephone.length >= 9  &&
      form.ville &&
      typeof form.ville === "string" &&
      form.email &&
      typeof form.email === "string"
    ) {
      await axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_ID}/${process.env.REACT_APP_FORM1}`,
          {
            fields: [
              {
                name: "firstname",
                value: form.fullName,
              },
              {
                name: "email",
                value: form.email,
              },
              {
                name: "phone",
                value: form.telephone,
              },
              {
                name: "city",
                value: form.ville,
              },
              {
                name: "message",
                value: client.isClient
                  ? "Oui"
                  : client.notClient === false
                  ? "Non"
                  : "Non",
              },
            ],
            context: {
              pageUri: "www.rafeek.ma",
              pageName: "rafeek",
            },
          }
        )
        .then(
          (resolve) => {
            setform((prevdata) => {
              return {
                ...prevdata,
                fullName: "",
                email: "",
                telephone: "",
                ville: "",
                isClient: "",
              };
            });
            setClient((prev) => {
              return {
                isClient: false,
                notClient: false,
              };
            });
            setError((prev)=>{
              return{
                ...prev,error:true,message:'Message envoyé'
              }
            })
            setRender(true)
            setInterval(()=>{
              setRender(false)
            },5000)
          },
          (reject) => {
            setError((prev)=>{
              return{
                ...prev,error:false,message:'Message non envoyé'
              }
            })
            setRender(true)
            setInterval(()=>{
              setRender(false)
            },5000)
          }
        );
    } else {
      setError((prev)=>{
        return{
          ...prev,error:false,message:'Message non envoyé'
        }
      })
      setRender(true)
      setInterval(()=>{
        setRender(false)
      },5000)
    }
  };
  return (
    <section className="contact-section-wrapper pt-md-5 pt-4" id="contact">
      <div className="container contact-wrapper">
        <div className="d-flex">
          <img className="contact-heading-icon" src={contacticon} alt="" />
          <h2>Prise de contact</h2>
        </div>
        <div className="row gap-4 justify-content-center p-lg-0 px-2 mt-3">
          <div className="col-xxl-3 col-lg-4 col-12 pt-5 contactez-nous-aside position-relative">
            <h3 className="text-center">Contactez-nous</h3>
            <p className="text-center mt-4 mb-0">
              Le responsable de famille Rafeek analysera tous <br />
              les besoins et vous conseillera sur les solutions 
              <br /> les plus adaptées en fonction de la situation <br/> de la personne à prendre en charge.
            </p>
            <div className="d-lg-none d-block px-md-4 px-2 contact-mobile-formulaire">
              <Formulaire change={handleFormChange} currentState={form} />
            </div>
            <img className="rafeek-contact-img" src={rafeekimg} alt="" />
          </div>

          <div className="d-lg-block d-none col-lg-7 col-12 pt-5 px-4 contactez-nous-coordonne">
            <h3>Coordonnées</h3>
            <form className="mt-5" onSubmit={handlesubmit}>
              <div className="d-flex justify-content-between align-items-center ">
                <p className="mb-0">Le client est l'auteur de la demande</p>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center me-5">
                    <input
                      id="oui"
                      className="input-checkbox"
                      type={"checkbox"}
                      title="oui"
                      checked={client.isClient}
                      name="isClient"
                      onChange={handleClient}
                    />
                    <label htmlFor="oui" className="ms-2">
                      Oui
                    </label>
                  </div>
                  <div className="d-flex align-items-center me-5">
                    <input
                      id="non"
                      className="input-checkbox"
                      type={"checkbox"}
                      title="non"
                      checked={client.notClient}
                      name="notClient"
                      onChange={handleClient}
                    />
                    <label htmlFor="non" className="ms-2">
                      Non
                    </label>
                  </div>
                </div>
              </div>
              <Formulaire change={handleFormChange} currentState={form} error={error.error} message={error.message} render={render}/>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
