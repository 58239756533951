import { useState,useRef,useEffect } from "react";
import axios from "axios";
import Formulaire from "./Formulaire";
import lineicon from "assets/icon/line-icon.svg";
const Rejoindre = () => {
  const fileRef = useRef(null)
  const [gendre, setgendre] = useState({
    men: false,
    women: false,
  });
  const [usertType, setUserType] = useState({
    candidat: false,
    partenaire: false,
  });
  const [data, setdata] = useState({
    fullName: "",
    telephone: "",
    ville: "",
    email: "",
  });
  const [error,setError] =useState({
    error:false,
    message:'Message non envoyé'
  })
  const [render,setRender] = useState(false)
  const [fileCv,setfileCv] = useState(null)
  const [fileStatus,setFileStatus] = useState({
    renderStatus:false,
    message:{
      isError:false,
      message:"Cette format n'est pas valide, ajouter un fichier doc, docx, pdf ou rtf."
    }
  })

  const handlechange = (e) => {
    setdata((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handlegendre = (e) => {
    if (e.target.name === "men") {
      if (gendre.women === true) {
        setgendre((prev) => {
          return { men: !prev.men, women: !prev.women };
        });
      } else {
        setgendre((prev) => {
          return { ...prev, [e.target.name]: !prev.men };
        });
      }
    } else {
      if (gendre.men === true) {
        setgendre((prev) => {
          return { men: !prev.men, women: !prev.women };
        });
      } else {
        setgendre((prev) => {
          return { ...prev, [e.target.name]: !prev.women };
        });
      }
    }
  };
  const handleUserType = (e) => {
    if (e.target.name === "candidat") {
      if (usertType.partenaire === true) {
        setUserType((prev) => {
          return { candidat: !prev.candidat, partenaire: !prev.partenaire };
        });
      } else {
        setUserType((prev) => {
          return { ...prev, [e.target.name]: !prev.candidat };
        });
      }
    } else {
      if (usertType.candidat === true) {
        setUserType((prev) => {
          return { partenaire: !prev.partenaire, candidat: !prev.candidat };
        });
      } else {
        setUserType((prev) => {
          return { ...prev, [e.target.name]: !prev.partenaire };
        });
      }
    }
  };

  const selectedGender = () => {
    if (gendre.men === true) {
      return true;
    } else if (gendre.women === true) {
      return true;
    } else {
      return false;
    }
  };
  const selectedUser = () => {
    if(usertType.candidat===true){
      return true
    }
    else if (usertType.partenaire===true){
      return true
    }
    else {
      return false
    }
  }
  const checkedGender = selectedGender();
  const checkedUser = selectedUser()
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (
      checkedGender &&
      checkedUser &&
      data.fullName &&
      typeof data.fullName === "string" &&
      data.telephone &&
      data.telephone.length >= 9 &&
      data.ville &&
      typeof data.ville === "string" &&
      data.email &&
      typeof data.email === "string"
    ) {
      await axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_ID}/${process.env.REACT_APP_FORM2}`,
          {
            fields: [
              {
                name: "gender",
                value: gendre.men
                  ? "M"
                  : gendre.women
                  ? "Mme"
                  : "M",
              },
              {
                name: "message",
                value: usertType.candidat
                  ? "Candidat"
                  : usertType.partenaire
                  ? "Partenaire"
                  : "Candidat",
              },
              {
                name: "firstname",
                value: data.fullName,
              },
              {
                name: "phone",
                value: data.telephone,
              },
              {
                name: "city",
                value: data.ville,
              },
              {
                name: "email",
                value: data.email,
              },
            ],
            context: {
              pageUri: "www.rafeek.ma",
              pageName: "rafeek",
            },
          }
        )
        .then(
          (resolve) => {
            setdata((prevdata) => {
              return {
                ...prevdata,
                fullName: "",
                email: "",
                telephone: "",
                ville: "",
              };
            });
            setUserType((prev) => {
              return {
                ...prev,
                candidat: false,
                partenaire: false,
              };
            });
            setgendre((prev) => {
              return {
                ...prev,
                men: false,
                women: false,
              };
            });
            setError(prev=>{
              return {
                ...prev,error:true,message:"Message envoyé"
              }
            })
            setRender(true)
            setInterval(()=>{
              setRender(false)
            },5000)
          },
          (reject) => {
            setError((prev)=>{
              return{
                ...prev,error:false,message:'Message non envoyé'
              }
            })
            setRender(true)
            setInterval(()=>{
              setRender(false)
            },5000)
          }
        );
        if(fileCv){
          const data = new FormData()
          data.append('file',fileCv)
          await axios.post(`${process.env.REACT_APP_FILE_ENDPOINT}`,data,{
            headers:{
              'Content-Type': 'multipart/form-data'
            }
          }).then(resolve=>{
            setfileCv(null)
            fileRef.current.value=null
            setFileStatus(prev=>{
              return{
                renderStatus:true,
                message:{isError:false,message:"Votre cv est envoyé"}
              }
            })
          })
        }
    } else {
      setError((prev)=>{
        return{
          ...prev,error:false,message:'Message non envoyé'
        }
      })
      setRender(true)
      setInterval(()=>{
        setRender(false)
      },5000)
    }
  };
  const handleFileChange = ()=>{
    const fileExtension = fileRef.current.files[0].name.slice((Math.max(0, fileRef.current.files[0].name.lastIndexOf(".")) || Infinity) + 1)
    if(fileExtension==="pdf" || fileExtension==="docx" || fileExtension==="doc" || fileExtension==="rtf"){
      setfileCv(fileRef.current.files[0])
    }
    else{
      setFileStatus(prev=>{
        return{
          renderStatus:true,
          message:{isError:true,message:"Cette format n'est pas valide, ajouter un fichier doc, docx, pdf ou rtf."}
        }
      })
    }
  }
  const handleClearFile = ()=>{
    fileRef.current.value = null
    setfileCv(null)
  }
  useEffect(()=>{
    const removeError = setTimeout(()=>{
      setFileStatus(prev=>{
        return{
          ...prev,renderStatus:false
        }
      })
    },5000)
    return () => clearTimeout(removeError);
  },[fileStatus])
  return (
    <section className="nous-rejoindre-wrapper" id="nous-rejoindre">
      <h2 className="text-center nous-rejoindre-heading">Nous rejoindre</h2>
      <div className="container d-flex flex-column align-items-center mt-md-5 mt-3">
        <div>
          <p className="nous-rejoindre-p">
            Vous avez une expérience probante dans{" "}
            <strong>
              l’accompagnement et la surveillance <br />
              de personnes âgées ? Aides-soignants, infirmiers auxiliaires ou polyvalents ?
            </strong>
          </p>
          <p className="nous-rejoindre-p mt-md-2 mt-0">
            Vous êtes une école de formation,
            <strong>
              un organisme de santé
              <br />
              ou une association qui opère dans le milieu médical ?
            </strong>
          </p>
          <h3 className="nous-rejoindre-h3">Rejoignez l’aventure !</h3>
        </div>

        <form className="nous-rejoindre-form mt-md-5 mt-4" onSubmit={handlesubmit}>
          <div className="d-flex justify-content-between postion-relative">
            <div className="d-flex align-items-center">
              <label className="me-2">M.</label>
              <input
                className="rejoindre-checkbox"
                type={"checkbox"}
                checked={gendre.men}
                name="men"
                onChange={handlegendre}
              />
            </div>
            <div className="d-flex align-items-center">
              <label className="me-2">Mme.</label>
              <input
                className="rejoindre-checkbox"
                type={"checkbox"}
                checked={gendre.women}
                name="women"
                onChange={handlegendre}
              />
            </div>
            <img className="line-icon" src={lineicon} alt="" />
            <div className="d-flex align-items-center">
              <label className="me-2">Candidat.</label>
              <input
                className="rejoindre-checkbox"
                type={"checkbox"}
                checked={usertType.candidat}
                name="candidat"
                onChange={handleUserType}
              />
            </div>
            <div className="d-flex align-items-center">
              <label className="me-2">Partenaire.</label>
              <input
                className="rejoindre-checkbox"
                type={"checkbox"}
                checked={usertType.partenaire}
                name="partenaire"
                onChange={handleUserType}
              />
            </div>
          </div>
          <Formulaire change={handlechange} currentState={data} error={error.error} message={error.message} render={render} handleFileChange={handleFileChange} fileRef={fileRef} fileCv={fileCv} renderFileSubmit={true} handleClearFile={handleClearFile} fileStatusRender={fileStatus.renderStatus} fileStatusMessage={fileStatus.message}/>
        </form>
      </div>
    </section>
  );
};

export default Rejoindre;
